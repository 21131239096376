import { TeamMember } from '../../api/team';
import { Icon } from '../../web/icon';
import { Translate } from '../../web/translate';

import { Avatar } from './avatar';

export function CarouselUserCard(props: {
  user: TeamMember;
  showSocialLinks: boolean;
  showSearchLink: boolean;
  closeCarousel: () => void;
}) {
  return (
    <div class="user-card user-card--compact">
      <div class="user-card__content js-carousel__inner__content">
        <div class="user-card__image">
          <Avatar image={props.user.avatar} name={props.user.name} modifier="compact" />
        </div>

        <div class="user-card__text">
          <h3 class="user-card__heading" data-field="user-name">
            {props.user.name}
          </h3>
          <span class="user-card__title" data-field="user-title">
            {props.user.title}
          </span>
        </div>
        <div class="user-card__links">
          {props.showSocialLinks && (
            <nav class="social-links social-links--compact" style="min-height:50px">
              {props.user.email && (
                <a
                  class="social-links__item"
                  href={'mailto:' + props.user.email}
                  target="_blank"
                  role="button"
                  rel="noreferrer">
                  <div class="social-links__icon social-links__icon--mail" role="presentation">
                    <Icon uiIcon="mail_circle" />
                  </div>
                  <span class="social-links__text">
                    <span class="is-hidden--text">Click here to </span>Send an email
                  </span>
                </a>
              )}

              {props.user.twitter && (
                <a
                  class="social-links__item"
                  href={props.user.twitter}
                  target="_blank"
                  rel="noreferrer">
                  <div class="social-links__icon social-links__icon--twitter" role="presentation">
                    <Icon uiIcon="twitter" />
                  </div>
                  <span class="social-links__text">
                    <span class="is-hidden--text">Click here to </span>Follow on Twitter
                  </span>
                </a>
              )}

              {props.user.facebook && (
                <a
                  class="social-links__item"
                  href={props.user.facebook}
                  target="_blank"
                  rel="noreferrer">
                  <div class="social-links__icon social-links__icon--facebook" role="presentation">
                    <Icon uiIcon="facebook" />
                  </div>
                  <span class="social-links__text">
                    <span class="is-hidden--text">Click here to </span>Connect on Facebook
                  </span>
                </a>
              )}
            </nav>
          )}
        </div>
      </div>
      {props.showSearchLink && props.user.searchUrl !== null && (
        <div class="user-card__button">
          <a
            class="button button--small button--secondary button--filled button--inline-pairs"
            href={props.user.searchUrl}
            onClick={props.closeCarousel}>
            <span class="button__content">
              <Translate>Find their next appointments</Translate>
            </span>
            <span class="button__icon">
              <Icon uiIcon="arrow_right" />
            </span>
          </a>
        </div>
      )}
    </div>
  );
}
