import { useSignal } from '@preact/signals';
import { useState } from 'preact/hooks';


import { AppointmentTypesApi } from '../../api/appointment_types';
import { TeamApi, TeamMember } from '../../api/team';
import { Icon } from '../../web/icon';
import { Translate } from '../../web/translate';
import { CarouselUserCard } from '../users/user_card_carousel';

import { Carousel } from './carousel';
import { HeaderDropDown } from './dropdown_menu';


interface TeamCarouselMenuData {
  loaded: boolean
  hasAppointmentTypes: boolean
  members: TeamMember[]
}

export function TeamCarouselMenu() {
  const open = useSignal(false),
    openCarousel = () => {
      open.value = !open.value;
    },
    closeCarousel = () => open.value = false;
  const [data, setData] = useState<TeamCarouselMenuData>({
    loaded: false,
    hasAppointmentTypes: false,
    members: []
  });

  const loadData = () => {
    const teamApi = new TeamApi(),
      appointmentTypesApi = new AppointmentTypesApi();
    Promise.all([teamApi.getTeam(), appointmentTypesApi.count()])
      .then(([team, count]) => {
        setData({ loaded: true, members: team, hasAppointmentTypes: count > 0 });
      });
  };

  const hasSocial = data.members.find(d => d.email.length > 0 || d.facebook.length > 0 || d.twitter.length > 0) !== undefined;
  return (
    <>
      <a class="site-nav__item__inner" role="button" onClick={openCarousel}>
        <span class="site-nav__item__text"><Translate hasFormatting>Team</Translate></span>
        <Icon class="site-nav__item__icon" uiIcon={open.value ? 'arrow_up' : 'arrow_down'} />
      </a>
      <HeaderDropDown open={open.value} close={closeCarousel} onLoadContent={loadData} contentLoaded={data.loaded}>
        <Carousel contentLoaded={data.loaded}>
          {data.members.map(m =>
            <div key={m.id} class="carousel__item">
              <div class="carousel__item__inner">
                <CarouselUserCard user={m} showSocialLinks={hasSocial} showSearchLink={data.hasAppointmentTypes} closeCarousel={closeCarousel} />
              </div>
            </div>
          ) }
        </Carousel>
      </HeaderDropDown>
    </>
  );
}


