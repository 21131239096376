import { getJson, postJson } from 'ts-comp/request';

export interface TeamMember {
  id: number;
  userId: number;
  avatar: string;
  searchUrl: string | null;
  name: string;
  title: string;
  description: string;
  email: string;
  twitter: string;
  facebook: string;
  resourceId: number;
}

export class TeamApi {
  protected basePath = '/api/team/';

  async getTeam() {
    return (await getJson(`${this.basePath}`)) as Promise<TeamMember[]>;
  }

  sort(items: Record<number, number>) {
    return postJson(`${this.basePath}sort`, { items });
  }
}
