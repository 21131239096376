import { useSignal } from '@preact/signals';
import { ComponentChild } from 'preact';
import { useEffect, useRef } from 'preact/hooks';

import { Icon } from '../../web/icon';
import { Translate } from '../../web/translate';

function getSlickOptions() {
  return {
    arrows: false,
    slidesToShow: 3,
    infinite: true,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 683,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true
        }
      }
    ]
  } as SlickOptions;
}

export function Carousel(props: { contentLoaded: boolean, children: ComponentChild[] }) {
  const slickParent = useSignal<HTMLDivElement | undefined>(undefined),
    carouselRef = useRef<HTMLDivElement>(null);

  const nextSlide = () => {
      if (slickParent.value !== undefined) {
        $(slickParent.value).slick('slickNext');
      }
    }, prevSlide = () => {
      if (slickParent.value !== undefined) {
        $(slickParent.value).slick('slickPrev');
      }
    };

  const hideArrowsOnDesk = props.children.length < 4;

  useEffect(() => {
    if (carouselRef.current !== null) {
      // Brief timeout to allow async components (Translate) to render before attaching slick
      window.setTimeout(() => {
        if (!carouselRef.current) {
          return;
        }

        $(carouselRef.current).one('init', (evt) => {
          const tgt = evt.currentTarget;

          slickParent.value = tgt;

          tgt.closest('.carousel')
            ?.classList.add('is-loaded');
        });

        const opts = getSlickOptions();
        $(carouselRef.current).slick(opts);
      }, 50);
    }
  }, [props.contentLoaded]);
  return (<>
    <div class="carousel">
      <button type="button" class={'carousel__button carousel__button--prev' + (hideArrowsOnDesk ? ' is-desk-hidden' : '')} role="button" onClick={prevSlide}>
        <span class="is-hidden--text"><Translate>Navigate to the previous item</Translate></span>
        <span class="carousel__button__icon" role="presentation"><Icon uiIcon="arrow_left" /></span>
      </button>
      <button type="button" class={'carousel__button carousel__button--next' + (hideArrowsOnDesk ? ' is-desk-hidden' : '')} role="button" onClick={nextSlide}>
        <span class="is-hidden--text"><Translate>Navigate to the next item</Translate></span>
        <span class="carousel__button__icon" role="presentation"><Icon uiIcon="arrow_right" /></span>
      </button>

      <div class="carousel__viewport">
        <div class="carousel__list" ref={carouselRef}>
          {props.children}
        </div>

      </div>
    </div>

  </>);
}

