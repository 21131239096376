import { useSignal } from '@preact/signals';
import { ComponentChildren } from 'preact';
import { useEffect } from 'preact/hooks';

import { Icon } from '../../web/icon';

interface DropdownProps {
  open: boolean, close: () => void
  onLoadContent: () => void
  contentLoaded: boolean
  children: ComponentChildren
}

export function HeaderDropDown(props: DropdownProps) {
  const loadStarted = useSignal(false);
  useEffect(() => {
    if (props.open && loadStarted.value === false) {
      props.onLoadContent();
    }
  }, [props.open]);

  return (<>
    <div class={'site-nav__mega-dropdown mega-dropdown' + (props.open ? ' is-active' : '')} aria-relevant="additions removals" aria-live="polite" >
      <div class="mega-dropdown__wrapper">
        <div class="mega-dropdown__inner">
          <h3 class="mega-dropdown__heading">Our team</h3>
          <a class="mega-dropdown__close" role="button" onClick={props.close}>
            <span class="is-hidden--text">Close this panel</span>
            <span role="presentation"><Icon uiIcon="cross" /></span>
          </a>
          {props.contentLoaded === false ?
            <div class="">
              <div class="loader">
                <div class="loader__text">
                This content will load when you open this panel
                </div>
                <div class="loader__icon" role="presentation">
                  <img src="/images/status/loading.gif" alt="Loading graphic" role="presentation" />
                </div>
              </div>
            </div>
            : props.children}
        </div>
      </div>
      <div class="mega-dropdown__mask" role="button" onClick={props.close}>
        <span class="is-hidden--text">Click here to close menu</span>
      </div>
    </div>
  </>);
}
