import { getJson } from 'ts-comp/request';
import { BaseApi } from './base';

export interface AppointmentTypeGetResult {
  id: number;
  name: string;
  showCost: boolean;
  cost: string | null;
}

export type AppTypeFilterViewModel = Pick<AppointmentTypeGetResult, 'id' | 'name'>;

export interface AppointmentTypeGetWithFilterResultLocation {
  id: number;
  name: string;
}

export interface AppointmentTypeGetWithFilterResultResource {
  id: number;
  name: string;
  defaultAppointmentTypeId: number | null;
}

export interface AppointmentTypeGetWithFilterResult {
  id: number;
  name: string;
  locations: Array<AppointmentTypeGetWithFilterResultLocation> | null;
  resources: Array<AppointmentTypeGetWithFilterResultResource>;
}

export interface AppointmentTypeDetailsResponse {
  location: AppTypeLocationViewModel;
  user: AppTypeUserViewModel;
  appointment: AppTypeAppointmentViewModel;
}

export interface AppTypeLocationViewModel {
  name: string;
  address: string;
  directions: string;
  directionsLink: string;
  mapLink: string;
  mapSettings: GoogleMapsLocationViewModel;
}

export interface GoogleMapsLocationViewModel {
  lat: number;
  lng: number;
  address: string;
}

export interface AppTypeUserViewModel {
  name: string;
  title: string;
  description: string;
  avatar: string;
  email: string;
  facebook: string;
  twitter: string;
  searchLink: string | null;
}

export interface AppTypeAppointmentViewModel {
  title: string;
  description: string;
  image: string;
  hasImage: boolean;
}

const cache: Map<number, Promise<AppointmentTypeGetResult>> = new Map();
const getWithFilterCache: Map<number, Promise<AppointmentTypeGetWithFilterResult>> = new Map();

let filterCache: Promise<AppTypeFilterViewModel[]> | undefined = undefined;

export class AppointmentTypesApi extends BaseApi {
  protected basePath = '/api/appointment-types/' as const;

  get(id: number) {
    return getJson(`${this.basePath}${id}`) as Promise<AppointmentTypeGetResult>;
  }

  getWithCache(id: number) {
    if (!cache.has(id)) {
      cache.set(id, this.get(id));
    }

    return cache.get(id)!;
  }

  getWithFilterCached(id: number) {
    if (!getWithFilterCache.has(id)) {
      getWithFilterCache.set(id, this.getWithFilter(id));
    }

    return getWithFilterCache.get(id)!;
  }

  getWithFilter(id: number) {
    return getJson(
      `${this.basePath}${id}/filter`,
      undefined,
      undefined,
      this.abortSignal
    ) as Promise<AppointmentTypeGetWithFilterResult>;
  }

  getForResource(userResourceId: number) {
    return getJson(
      `${this.basePath}${userResourceId}/resource`,
      undefined,
      undefined,
      this.abortSignal
    ) as Promise<AppTypeFilterViewModel>;
  }

  filter() {
    return getJson(`${this.basePath}filter`, undefined, undefined, this.abortSignal) as Promise<
      AppTypeFilterViewModel[]
    >;
  }

  filterWithCache() {
    if (filterCache === undefined) {
      filterCache = this.filter();
    }

    return filterCache;
  }

  count() {
    return getJson(
      `${this.basePath}count`,
      undefined,
      undefined,
      this.abortSignal
    ) as Promise<number>;
  }

  async details(
    appTypeId: number,
    userId: number,
    locationId?: number
  ): Promise<AppointmentTypeDetailsResponse> {
    let url = `${this.basePath}${appTypeId}/details/u/${userId}`;
    if (locationId !== undefined) {
      url += `/l/${locationId}`;
    }

    return getJson(url, undefined, undefined, this.abortSignal);
  }
}
