export function Avatar(props: { image: string, name: string, modifier?: 'large' | 'square' | 'compact' }) {
  const modifier = props.modifier ? 'avatar--' + props.modifier : '';

  if (props.image === undefined || props.image.length === 0) {
    return (
      <div class={'default-avatar ' + modifier}>
        <div class="default-avatar__text ">{props.name[0] }</div>
      </div>
    );
  }

  return (
    <div class={'avatar ' + modifier}>
      <img src={props.image} alt={props.name} />
    </div>
  );
}
